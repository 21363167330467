import { Status as ReservationStatus, Type as ReservationType, Origin } from 'enums/reservation';
import { BookingFormData } from 'types/app/booking';

export const bookingInitialState: BookingFormData = {
  reservation_date: null,
  status: ReservationStatus.CONFIRMED,
  type: ReservationType.BOOKING,
  start_time: 0,
  end_time: 0,
  guests: 0,
  kids: 0,
  channel: null,
  tables: [],
  lock_table: false,
  comments: [],
  client_comment: undefined,
  staff_comment: undefined,
  origin: Origin.PHONE,
  tags: [],
  waiter_id: null,
  special_offer: null,
  files: [],
  created_at: 0,
  updated_at: 0,
  original_start_time: 0,
  notifications: {
    language: 'en',
    sms: false,
    email: false,
    resend: false,
    table_ready: false,
  },
  client: {
    name: '',
    email: '',
    phone: '',
    company: '',
    subscribed: false,
    tags: [],
  },
  payments: null,
  stripe_payment_data: null,
  late_cancellation: null,
  widget_fields: [],
};
