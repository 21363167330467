import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { NotificationStatus } from 'types/app/notificationSettings';
import { merge } from 'lodash';
import { getStatusColor, getTypeColor } from 'utils/data-processors/getStatusColors';
import { snakeToCamelCase } from 'utils/str/snakeToCamelCase';
import { t } from 'i18next';
import { getTypeIcon } from 'app/components/BookingModal/_config';
import { SubStatus as ReservationSubStatus } from 'enums/reservation';
import { Status as ReservationStatus, Type as ReservationType } from 'enums/reservation';

const booking = (state: RootState) => state.booking;
const selectedType = (state: RootState) => state.booking.type;
const selectedSubStatus = (state: RootState) => state.booking.sub_status;

const selectedStatus = (state: RootState) => state.booking.status;

const excludedStatuses = [ReservationStatus.PRE_BOOKED];

const clients = (state: RootState) => state.clients;

const defaultLanguage = (state: RootState) =>
  state.app.settings.time_and_language.widget_default_language;
const smsNotification = (state: RootState) => state.app.settings.notification_settings.sms;
const preselectedNotifications = (state: RootState) =>
  state.app.settings.reservation.preselected_notifications;

export const selectInitialFormValues = createSelector(
  [booking, defaultLanguage, smsNotification, preselectedNotifications],
  (booking, defaultLanguage, smsNotification, preselectedNotifications) => {
    return merge({}, booking.initialValues, {
      notifications: {
        language: defaultLanguage,
        sms: booking.isNew
          ? smsNotification.status === NotificationStatus.ACTIVE
          : preselectedNotifications,
        email: booking.isNew || preselectedNotifications,
      },
    });
  },
);

const reservationTypes = (state: RootState) => state.app.config.reservation_types;

export const selectReservationTypeStatuses = createSelector(
  [selectedType, reservationTypes, selectedStatus],
  (selectedType, reservationTypes, selectedStatus) => {
    const statuses = reservationTypes.find(
      (reservationType) => reservationType.type === selectedType,
    )?.statuses;

    if (selectedStatus !== ReservationStatus.PRE_BOOKED) {
      return statuses?.filter((status) => !excludedStatuses.includes(status));
    }

    return statuses;
  },
);

export const selectReservationTypeSubStatuses = createSelector(
  [selectedType, reservationTypes],
  (selectedType, reservationTypes) =>
    reservationTypes.find((reservationType) => reservationType.type === selectedType)?.sub_statuses,
);

export const bookingConditions = createSelector(
  [selectedType, selectedSubStatus],
  (selectedType, selectedSubStatus) => {
    const conditions = {
      showGuestDetails: true,
      showReservationTags: true,
      showExperiences: true,
      showFilesAndExtraInformation: true,
      showAddGuestDetails: false,
      showComments: true,
      showGuestNote: true,
    };
    switch (selectedType) {
      case ReservationType.BLOCKED:
        conditions.showReservationTags = false;
        conditions.showExperiences = false;
        conditions.showFilesAndExtraInformation = false;

        switch (selectedSubStatus) {
          case ReservationSubStatus.WITHOUT_NAME:
            conditions.showGuestDetails = false;
            conditions.showGuestNote = false;
        }
        break;
      case ReservationType.WALK_IN:
        switch (selectedSubStatus) {
          case ReservationSubStatus.WITHOUT_NAME:
            conditions.showGuestDetails = false;
            conditions.showGuestNote = false;
        }

        break;
    }

    return conditions;
  },
);

export const selectBookingClient = createSelector([booking, clients], (booking, clients) => {
  return clients.find((client) => client.id === booking.client?.id);
});

export const selectReservationTypes = createSelector([reservationTypes], (reservationTypes) =>
  reservationTypes.map((reservationType) => {
    return {
      label: t(snakeToCamelCase(reservationType.type)),
      value: reservationType.type,
      icon: getTypeIcon(reservationType.type),
      colors: getTypeColor(reservationType.type),
    };
  }),
);

export const selectReservationStatusOptions = createSelector(
  [selectReservationTypeStatuses],
  (statuses) =>
    statuses?.map((status) => ({
      label: t(snakeToCamelCase(status)),
      value: status,
      colors: getStatusColor(status),
    })) || [],
);

export const selectReservationSubStatusOptions = createSelector(
  [selectReservationTypeSubStatuses],
  (subStatuses) =>
    subStatuses?.map((subStatus) => ({
      label: t(snakeToCamelCase(subStatus)),
      value: subStatus,
      colors: getStatusColor(subStatus),
    })) || [],
);
