import { ProfileState } from 'types/app/profile';
import { UserRole, UserStatuses } from 'types/app/users';

export const profileInitialState: ProfileState = {
  id: 0,
  name: '',
  email: '',
  status: UserStatuses.Active,
  role: UserRole.Staff,
  permissions: [],
  restaurants: [],
  waiters: [],
  drupal_roles: [],
  drupal_permissions: {},
  features: [],
};
