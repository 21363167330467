import { ReservationSettingsState } from 'types/app/reservationSettings';

export const reservationInitialState: ReservationSettingsState = {
  phone_required: false,
  default_booking_length: 7200,
  auto_arrival: false,
  slot_guests: false,
  event_modal: false,
  no_show: false,
  walk_in: false,
  canceled: false,
  big_spender_tag: false,
  black_list_tag: false,
  original_time: false,
  preselected_notifications: false,
  interval: 0,
  show_stats: true,
  full_view_calendar: true,
  show_walk_in_list: true,
  show_phone_list: true,
};
